<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg11 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-domain</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <!-- :error-messages="nomeErrors" 
                              required
                  @input="$v.empresaLocal.nome_empresa.$touch()"
                  @blur="$v.empresaLocal.nome_empresa.$touch()"-->

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="1" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="empresaLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="empresaLocal.alias"
                    label="Alias"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="7" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="empresaLocal.razaosocial"
                    label="Razão Social"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="empresaLocal.cnpj"
                    label="CNPJ"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="empresaLocal.cep"
                    label="CEP"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="7" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="empresaLocal.endereco"
                    label="Endereço"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="empresaLocal.bairro"
                    label="Bairro"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="empresaLocal.cidade"
                    label="Cidade"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="empresaLocal.uf"
                    label="UF"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="empresaLocal.fones"
                    label="Fones"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar()"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
          <!-- <template>
            <tableCompras />
          </template> -->
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";

const { mapState, mapActions } = createNamespacedHelpers("lojaEmpresas");

export default {
  mixins: [validationMixin, DatasMixin],
  // components: { tableCompras },
  // validations: {
  //   empresaLocal: {
  //     nome_empresa: { required },
  //     email: { required, email },
  //     empresa: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    name: "LojaEmpresasForm",
    tituloForm: "",
    empresaLocal: {},
    comprasIconDisable: false,
    selectStatus: ["Aguardando", "Andamento", "Concluida", "Arquivada"],
  }),

  computed: {
    ...mapState(["empresaSelect", "listaUsuarios"]),
    // nomeErrors() {
    //   const errors = [];
    //   if (!this.$v.empresaLocal.nome_empresa.$dirty) return errors;
    //   !this.$v.empresaLocal.nome_empresa.required &&
    //     errors.push("Nome requerido.");
    //   return errors;
    // },
    // emailErrors() {
    //   const errors = [];
    //   if (!this.$v.empresaLocal.email.$dirty) return errors;
    //   !this.$v.empresaLocal.email.email && errors.push("E-mail inválido");
    //   !this.$v.empresaLocal.email.required && errors.push("E-mail requerido");
    //   return errors;
    // },
    // empresaErrors() {
    //   const errors = [];
    //   if (!this.$v.empresaLocal.empresa.$dirty) return errors;
    //   !this.$v.empresaLocal.empresa.required &&
    //     errors.push("Usuário requerido.");
    //   return errors;
    // },
    // senhaErrors() {
    //   const errors = [];
    //   if (!this.$v.empresaLocal.senha.$dirty) return errors;
    //   !this.$v.empresaLocal.senha.required && errors.push("Senha requerida.");
    //   return errors;
    // },
  },

  watch: {
    empresaSelect(empresaNovo) {
      this.sincronizar(empresaNovo);
    },
  },

  created() {
    this.sincronizar(this.empresaSelect);
    if (this.empresaSelect) {
      this.tituloForm = "Editar Empresa";
    } else {
      this.tituloForm = "Nova Empresa";
    }
    this.formataDatasDB();
  },
  methods: {
    ...mapActions([
      "selecionarEmpresa",
      "resetarEmpresa",
      "criarEmpresa",
      "editarEmpresa",
      "resetarErro",
    ]),

    obterUsuario(id) {
      if (id != null) {
        const ret = this.listaUsuarios.filter((g) => g.id == id);
        return ret[0].usuario;
      } else {
        const ret = "";
        return ret;
      }
    },

    selecionarEmpresaEdicao(empresa) {
      this.selecionarEmpresa({ empresa });
    },

    async salvarEmpresa() {
      switch (this.empresaSelect) {
        case undefined:
          this.empresaLocal.cria_usuario = this.$store.state.login.usuario.id;
          this.empresaLocal.cria_datahora = this.mixDataAgoraBR();
          this.formataDatasForm();
          await this.criarEmpresa({
            empresa: this.empresaLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.formataDatasDB();
          if (this.$store.state.lojaEmpresas.erro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.lojaEmpresas.erro,
            });
            this.resetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Empresa Criada!!",
            });
          }
          break;
        default:
          this.empresaLocal.mod_usuario = this.$store.state.login.usuario.id;
          this.empresaLocal.mod_datahora = this.mixDataAgoraBR();
          this.formataDatasForm();
          await this.editarEmpresa({
            empresa: this.empresaLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.formataDatasDB();
          if (this.$store.state.lojaEmpresas.erro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.lojaEmpresas.erro,
            });
            this.resetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Empresa Salva!!",
            });
          }
      }
      //this.resetar();
    },

    formataDatasForm() {
      this.empresaLocal.cria_datahora = this.mixDataFormD(
        this.empresaLocal.cria_datahora
      );
      this.empresaLocal.mod_datahora = this.mixDataFormD(
        this.empresaLocal.mod_datahora
      );
    },
    formataDatasDB() {
      this.empresaLocal.cria_datahora = this.mixDataDBD(
        this.empresaLocal.cria_datahora
      );
      this.empresaLocal.mod_datahora = this.mixDataDBD(
        this.empresaLocal.mod_datahora
      );
    },

    sincronizar(novoEmpresa) {
      this.empresaLocal = Object.assign(
        {},
        novoEmpresa || {
          id: null,
          // cria_datahora: null,
          // mod_datahora: null,
        }
      );
    },
    submit() {
      // this.$v.$touch();
      this.salvarEmpresa();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.empresaLocal.ultima_compra = this.empresaSelect.ultima_compra;
      // this.empresaLocal.email = this.empresaSelect.email;
      // this.empresaLocal.empresa = this.empresaSelect.empresa;
      // this.empresaLocal.senha = this.empresaSelect.senha;
      // this.empresaLocal.ativo = this.empresaSelect.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
